import TwitterIcon from "@mui/icons-material/Twitter";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Icon,
  Paper,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { correctAnswerSent } from "../api/cloudFunctionsCaller";
import { Floor, Guild, User } from "../interface";
import {
  useIsFloorsRevealed,
  useIsGuildRevealed109Problem,
  useOneFloorData,
  useRevealedFloorNumber,
} from "../util/floorHooks";
import AnswerKeyInput from "./AnswerKeyInput";
import { Canvas } from "./Canvas/Canvas";
import { CorrectDialog } from "./dialog/CorrectDialog";
import { WrongDialog } from "./dialog/WrongDialog";
import { NotFound } from "./NotFound";
import { NowLoading } from "./NowLoading";
import { PageTitle } from "./typography/PageTitle";

const SolutionArea = ({ solutionImage }: { solutionImage: string }) => {
  return (
    <Grid component="div" sx={{ my: 1 }}>
      <img src={solutionImage} width="100%" height="auto"></img>
    </Grid>
  );
};

const ProblemArea = ({
  floorData,
  floorIndex,
  handleCollectAnswer,
  handleWrongAnswer,
}: {
  floorData: Floor;
  floorIndex: number;
  handleCollectAnswer: () => Promise<void>;
  handleWrongAnswer: () => Promise<void>;
}) => {
  return (
    <>
      <Grid component="div" sx={{ my: 1 }}>
        <Canvas imageUrl={floorData.problemImage} />
      </Grid>
      <AnswerKeyInput
        floorId={(floorIndex + 1).toString()}
        answerArray={floorData.answerArray}
        handleCollectAnswer={handleCollectAnswer}
        handleWrongAnswer={handleWrongAnswer}
      />
    </>
  );
};

export const FloorPage = ({
  userData,
  uid,
  guildData,
  floorsCount,
}: {
  userData: User;
  uid: string;
  guildData: Guild;
  floorsCount: number;
}) => {
  const temp = useParams<{ floorIndex: string }>();
  const [monthName, setMonthName] = useState("");
  const [floorIndex, setFloorIndex] = useState(-1); //0-index
  const [isFloorSolved, setIsFloorSolved] = useState(false);
  const [monthNum, setMonthNum] = useState(0);
  const [problemNum, setProblemNum] = useState(0);
  let history = useHistory();
  const isGuildRevealed109Problem = useIsGuildRevealed109Problem(guildData);
  const revealedFloorNumber = useRevealedFloorNumber(isGuildRevealed109Problem);
  const floorData = useOneFloorData(floorIndex + 1);

  useEffect(() => {
    const tempFloorIndexNum = parseInt(temp.floorIndex, 10);
    setFloorIndex(tempFloorIndexNum - 1);
    const monthNum = Math.ceil(tempFloorIndexNum / 9);
    setMonthNum(monthNum);
    setProblemNum(tempFloorIndexNum - 9 * (monthNum - 1));
    if (monthNum <= 12) {
      setMonthName(
        `${monthNum.toString()}月‐${(
          tempFloorIndexNum -
          9 * (monthNum - 1)
        ).toString()}`
      );
    } else {
      setMonthName("年越し謎2021ex");
    }
  }, [temp.floorIndex]);

  const handleChallengeTweet = () => {
    if (monthName === "年越し謎2021ex") {
      window.open(
        `https://twitter.com/intent/tweet?text=${monthName} ${floorData?.title.replace(
          "#",
          "%23"
        )}%20に挑戦します！%20%23年越し謎2021&url=https://goodbye2021.enigmahouse.jp/`
      );
    } else {
      window.open(
        `https://twitter.com/intent/tweet?text=${monthName} ${floorData?.title
          .replace("#", "%23")
          .replace(
            "&",
            "%26"
          )}%20に挑戦します！%20%23年越し謎2021&url=https://goodbye2021.enigmahouse.jp/share/2021-${monthNum.toString()}-${problemNum.toString()}`
      );
    }
  };

  const handleSolvedTweet = () => {
    if (monthName === "年越し謎2021ex") {
      window.open(
        `https://twitter.com/intent/tweet?text=${monthName} ${floorData?.title.replace(
          "#",
          "%23"
        )}%20を見事解き明かした！%20%23年越し謎2021&url=https://goodbye2021.enigmahouse.jp/`
      );
    } else {
      window.open(
        `https://twitter.com/intent/tweet?text=${monthName} ${floorData?.title
          .replace("#", "%23")
          .replace(
            "&",
            "%26"
          )}%20を見事解き明かした！%20%23年越し謎2021&url=https://goodbye2021.enigmahouse.jp/share/2021-${monthNum.toString()}-${problemNum.toString()}`
      );
    }
  };

  const [tabValue, setTabValue] = React.useState("problem");

  const handleTabChange = (
    event: React.SyntheticEvent | undefined,
    newValue: string
  ) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (userData.floors[temp.floorIndex.toString()]) {
      setIsFloorSolved(true);
    }
  }, [userData.floors, temp.floorIndex]);

  const [successOpen, setSuccessOpen] = useState(false);
  const [wrongOpen, setWrongOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleCollectAnswer = async () => {
    setIsButtonDisabled(true);
    const result = await correctAnswerSent(uid, (floorIndex + 1).toString());
    setIsFloorSolved(result.isCorrect);
    setTabValue("solution");

    setSuccessOpen(true);
    setIsButtonDisabled(false);
  };
  const handleWrongAnswer = async () => {
    setWrongOpen(true);
  };

  const handleClose = () => {
    setSuccessOpen(false);
    setWrongOpen(false);
  };

  const { isFloorsRevealed } = useIsFloorsRevealed(
    floorsCount,
    revealedFloorNumber
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isButtonDisabled}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {floorData && isFloorsRevealed[floorIndex] ? (
        <Paper>
          <p>{monthName}</p>
          <PageTitle>{floorData.title}</PageTitle>
          <div>
            by{" "}
            {floorData.authorTwitterAccount ? (
              <a
                href={`https://twitter.com/${floorData.authorTwitterAccount}`}
                style={{ color: "#fff" }}
              >
                {floorData.author}
              </a>
            ) : (
              <>{floorData.author}</>
            )}
          </div>
          <Box sx={{ my: 1 }}>
            {tabValue === "problem" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleTabChange(undefined, "solution");
                }}
              >
                解説を見る
              </Button>
            )}
            {tabValue === "solution" && (
              <Button
                variant="contained"
                onClick={() => {
                  handleTabChange(undefined, "problem");
                }}
              >
                問題を見る
              </Button>
            )}
          </Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="問題" value="problem" />
                <Tab label="解説" value="solution" />
              </TabList>
            </Box>
            <TabPanel value="problem">
              <ProblemArea
                floorIndex={floorIndex}
                handleWrongAnswer={handleWrongAnswer}
                handleCollectAnswer={handleCollectAnswer}
                floorData={floorData}
              />
            </TabPanel>
            <TabPanel value="solution">
              <SolutionArea solutionImage={floorData.solutionImage} />
            </TabPanel>
          </TabContext>

          {isFloorSolved ? (
            <Button
              variant="contained"
              startIcon={<TwitterIcon />}
              color="info"
              sx={{ my: 1 }}
              onClick={handleSolvedTweet}
            >
              正解した事をツイート
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<TwitterIcon />}
              color="info"
              sx={{ my: 1 }}
              onClick={handleChallengeTweet}
            >
              挑戦する事をツイート
            </Button>
          )}
          <Grid component="div" sx={{ my: 1 }}>
            <Button
              variant="contained"
              startIcon={<Icon>apps</Icon>}
              onClick={() => {
                history.push("/");
              }}
            >
              年越し謎2021トップに戻る
            </Button>
          </Grid>
        </Paper>
      ) : floorData === undefined ? (
        <NowLoading />
      ) : (
        <NotFound />
      )}

      <CorrectDialog open={successOpen} closeHandler={handleClose}>
        {monthName} {floorData?.title}を見事解き明かした！
        <br />
        正解したことをシェアできます。
        <br />
        <Button
          variant="contained"
          startIcon={<TwitterIcon />}
          color="info"
          sx={{ my: 1 }}
          onClick={handleSolvedTweet}
        >
          ツイート
        </Button>
      </CorrectDialog>
      <WrongDialog open={wrongOpen} closeHandler={handleClose}>
        もう一度お考えください。
      </WrongDialog>
    </>
  );
};
