import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "firebase/functions";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { saveUserName } from "../../api/cloudFunctionsCaller";

export const ChangeUserName = ({
  newUser,
  uid,
  setIsButtonDisabled,
  isGuestMode,
}: {
  newUser: boolean;
  uid: string;
  setIsButtonDisabled: (arg: boolean) => void;
  isGuestMode: boolean;
}) => {
  const history = useHistory();

  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [message, setMessage] = useState("");

  const clearMessage = () => {
    setMessage("");
  };
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };
  const handleButton = async () => {
    setIsButtonDisabled(true);
    await saveUserName({ uid, userNameInputValue, setMessage });
    setIsButtonDisabled(false);
  };

  return (
    <>
      {!isGuestMode && (
        <Card sx={{ my: 2 }}>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1">
                ユーザー名の変更
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ my: 2 }}>
              <TextField
                variant="outlined"
                label="ユーザー名"
                value={userNameInputValue}
                onChange={handleUserNameInputValue}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 2 }}>
              <Button variant="contained" onClick={handleButton}>
                変更を保存する
              </Button>
              <div>{message}</div>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
