import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { saveUserName } from "../api/cloudFunctionsCaller";
import { Guild, User } from "../interface";
import { ConfigProps } from "./Config";
import { Rule } from "./Rule";

export type NewUserProps = {
  guildData: Guild;
  userData: User;
  uid: string;
  isButtonDisabled: boolean;
  setIsButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setUid: (prop: string) => void;
  setIsGuestMode: (prop: boolean) => void;
  isGuestMode: boolean;
};

export const NewUser = (props: NewUserProps) => {
  const {
    isButtonDisabled,
    setIsButtonDisabled,
    guildData,
    userData,
    uid,
    setUid,
    setIsGuestMode,
    isGuestMode,
  } = props;
  const configProps: ConfigProps = { guildData, userData, uid };

  const [newUserState, setNewUserState] = useState("twitter");
  const [open, setOpen] = useState(false);

  const [userNameInputValue, setUserNameInputValue] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (
      uid !== "" &&
      (newUserState === "name" || newUserState === "twitter") &&
      (userData.userName ?? "") !== ""
    ) {
      setNewUserState("rule");
    } else if (uid !== "" && newUserState === "twitter") {
      setNewUserState("name");
    }
  }, [uid, userData.userName]);

  const clearMessage = () => {
    setMessage("");
  };
  const handleUserNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearMessage();
    const newName = e.target.value;
    setUserNameInputValue(newName);
  };
  const handleUserNameSaveButton = async () => {
    // setIsButtonDisabled(true);
    setOpen(true);
    if (uid !== "") {
      await saveUserName({ uid, userNameInputValue, setMessage });
    }
    // setIsButtonDisabled(false);
    setOpen(false);
    setNewUserState("rule");
  };
  const skipTwitterLogin = () => {
    setNewUserState("rule");
    setIsGuestMode(true);
  };

  const handleLoginButton = () => {
    window.location.href = `https://enigmahouse.jp/login/${process.env.REACT_APP_LOGIN_URL}`;
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {newUserState === "twitter" && (
        <>
          <Grid item xs={12}>
            <h1> 年越し謎へようこそ!</h1>
          </Grid>
          <Grid item xs={12}>
            <p>
              本サイトではtwitterアカウントにログインすることで、次の機能を利用することが出来ます。
            </p>
            <ul>
              <li>解答履歴を保存する事ができます。</li>
              <li>ギルドを組んで参加することが出来ます。</li>
            </ul>
            <p>注意事項</p>
            <ul>
              <li>
                ログインしなくてもゲストモードで遊ぶことは出来ますが、解答履歴は保存されません。
                <br />
                はじめからログインすることをおすすめします。
              </li>
              <li>
                なお、後からログインする場合はユーザー設定ページからログインできます。
              </li>
            </ul>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginButton}
              sx={{ m: 0.5 }}
            >
              twitterログインページを開く
            </Button>
            <Button
              onClick={skipTwitterLogin}
              variant="contained"
              sx={{ m: 0.5 }}
            >
              ゲストモードで遊ぶ
            </Button>
          </Grid>
        </>
      )}

      {newUserState == "name" && (
        <>
          <Grid item xs={12}>
            <p>このサイトで使用する名前を入力してください。</p>
            <p>後からでも変更できます。</p>
          </Grid>
          <Grid>
            <TextField
              variant="outlined"
              label="ユーザー名"
              value={userNameInputValue}
              onChange={handleUserNameInputValue}
            ></TextField>
            <Button
              variant="contained"
              disabled={isButtonDisabled}
              onClick={handleUserNameSaveButton}
            >
              登録
            </Button>
          </Grid>
        </>
      )}
      {newUserState == "rule" && (
        <>
          <Rule {...props} />
        </>
      )}
    </>
  );
};
