import { Button, Grid, Icon } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Guild, User } from "../interface";
import { PageTitle } from "./typography/PageTitle";
import img1 from "../img/FHykxBTacAEyvmi.png";
import img2 from "../img/FHyklOxaUAIUye4.png";
import img3 from "../img/FHykz9FakAcftEP.png";
import img4 from "../img/FHyk6IeaAAACPHP.png";

type RuleProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Rule = (props: RuleProps) => {
  const { guildData, userData, uid } = props;
  const history = useHistory();
  const handleStartButton = async () => {
    history.push("/");
  };

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const onResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, [setSize]);

  useEffect(() => {
    window.addEventListener("resize", onResize, true);
    return () => {
      window.removeEventListener("resize", onResize, true);
    };
  }, []);

  return (
    <>
      <Grid item xs={12}>
            <h1></h1>
        <PageTitle>年越し謎2021</PageTitle>
      </Grid>
      <Grid item xs={12}>
        <img src={img1} style={{ width: "100%" }}></img>
        <img src={img2} style={{ width: "100%" }}></img>
        <img src={img3} style={{ width: "100%" }}></img>
        <img src={img4} style={{ width: "100%" }}></img>
        <ul>
          <li>
            トラブルや疑問点などがありましたら
            <a
              href="https://twitter.com/toshikoshinazo"
              style={{ color: "#fff" }}
            >
              年越し謎公式
            </a>
            にお問い合わせください。
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Button variant="contained" onClick={handleStartButton}>
          年越し謎を始める
        </Button>
      </Grid>
    </>
  );
};
