import {
  Dialog,
  Alert,
  DialogTitle,
  AlertTitle,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { FC } from "react";

export type WrongDialogProps = {
  open: boolean;
  closeHandler: () => void;
};
export const WrongDialog: FC<WrongDialogProps> = ({
  open,
  closeHandler,
  children,
}) => (
  <Dialog
    open={open}
    // eslint-disable-next-line no-restricted-globals
    onClose={close}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    color="error"
  >
    <Alert severity="error">
      <DialogTitle id="alert-dialog-title">
        <AlertTitle
          sx={{
            fontSize: "34px",
            fontWeight: "bold",
          }}
        >
          不正解です！
        </AlertTitle>
      </DialogTitle>
      <DialogContentText id="alert-dialog-description">
        {children}
      </DialogContentText>
      <DialogActions>
        <Button onClick={closeHandler}>閉じる</Button>
      </DialogActions>
    </Alert>
  </Dialog>
);
