import { useEffect, useState } from "react";
import {
  getIsGuildRevealed109Problem,
  getRevealedFloorNumberSnapshot,
  loadThumbnailData,
} from "../api/dataLoader";
import { getOneFloorFromGoogleSheet } from "../api/googleSheetReader";
import { Floor, Guild, ThumbnailData, User } from "../interface";

// 開放済みフロアのサムネイルデータ取得
export const useThumbnailData = (revealedFloorNumber: number) => {
  const [thumbnailData, setThumbnailData] = useState(
    new Map<string, ThumbnailData>()
  );
  useEffect(() => {
    const f = async () => {
      const tempThumbnailData = await loadThumbnailData(revealedFloorNumber);
      setThumbnailData(tempThumbnailData);
    };
    f();
  }, [revealedFloorNumber]);
  return thumbnailData;
};

// 1フロアデータ取得
export const useOneFloorData = (floorNumber: number) => {
  const [floorData, setFloorData] = useState<Floor | undefined | null>(
    undefined
  );
  useEffect(() => {
    const f = async () => {
      setFloorData(await getOneFloorFromGoogleSheet(floorNumber));
    };
    if (floorNumber >= 1) {
      f();
    }
  }, [floorNumber]);
  return floorData;
};

// プレイヤーの進捗取得
export const useIsFloorsSolvedByPlayer = (
  floorsCount: number,
  userData: User
) => {
  const [isFloorsSolvedByUser, setIsFloorsSolvedByUser] = useState<boolean[]>(
    Array(floorsCount).fill(false)
  );

  useEffect(() => {
    let newIsFloorsSolved: boolean[] = Array(floorsCount + 1).fill(false);
    newIsFloorsSolved = newIsFloorsSolved.map(
      (_, floorIndex) =>
        !(userData.floors[(floorIndex + 1).toString()] === undefined)
    );
    setIsFloorsSolvedByUser(newIsFloorsSolved);
  }, [userData.floors]);

  return isFloorsSolvedByUser;
};

// ギルド進捗関連の更新
export const useIsFloorsSolvedByGuild = (
  floorsCount: number,
  guildData: Guild
) => {
  const [isFloorsSolvedByGuild, setIsFloorsSolvedByGuild] = useState<
    Array<boolean>
  >(Array(floorsCount).fill(false));
  useEffect(() => {
    let newIsFloorsSolvedByGuild: Array<boolean> = Array(floorsCount + 1).fill(
      false
    );
    newIsFloorsSolvedByGuild = newIsFloorsSolvedByGuild.map(
      (_, floorIndex) =>
        !(guildData.floors[(floorIndex + 1).toString()] === undefined)
    );
    setIsFloorsSolvedByGuild(newIsFloorsSolvedByGuild);
  }, [guildData.floors, floorsCount]);
  return isFloorsSolvedByGuild;
};

// 108問全問解いたかどうかフラグ
export const useIsGuildSolvedAll108Problem = (
  floorsCount: number,
  isFloorsSolvedByGuild: Array<boolean>
) => {
  const [isGuildSolved108Problem, setIsGuildSolved108Problem] = useState(false);
  useEffect(() => {
    let newIsGuildSolved108Problem = true;
    for (let i = 1; i < floorsCount; i++) {
      newIsGuildSolved108Problem =
        newIsGuildSolved108Problem && isFloorsSolvedByGuild[i];
      setIsGuildSolved108Problem(newIsGuildSolved108Problem);
    }
  }, [isFloorsSolvedByGuild, floorsCount]);
  return isGuildSolved108Problem;
};

// 何問目まで解禁されているか。
// ユーザーが109問目を解禁したか。
//(109問目を解禁している⇒108問目まで解禁されているが保証されていることに依存)

export const useRevealedFloorNumber = (isGuildRevealed109Problem: boolean) => {
  /*const [revealedFloorNumber, setRevealedFloorNumber] = useState(-1);
  let stopRevealedFloorNumberSnapshot = () => {};

  useEffect(() => {
    if (isGuildRevealed109Problem) {
      stopRevealedFloorNumberSnapshot();
      stopRevealedFloorNumberSnapshot = () => {};
      if (revealedFloorNumber != 109) {
        setRevealedFloorNumber(109);
      }
      return;
    }
    (async () => {
      stopRevealedFloorNumberSnapshot();
      stopRevealedFloorNumberSnapshot = await getRevealedFloorNumberSnapshot(
        setRevealedFloorNumber
      );
    })();
  }, [isGuildRevealed109Problem]);*/
  const revealedFloorNumber = 109;
  return revealedFloorNumber;
};

// 109問目を開放する処理をギルドの誰かが行ったか
export const useIsGuildRevealed109Problem = (guildData: Guild) => {
  const [isGuildRevealed109Problem, setIsGuildRevealed109Problem] =
    useState(false);
  useEffect(() => {
    const f = async () => {
      if (guildData.memberIds.length > 0) {
        const ret = await getIsGuildRevealed109Problem(guildData.memberIds);
        setIsGuildRevealed109Problem(ret);
      }
    };
    f();
  }, [guildData.memberIds]);
  return isGuildRevealed109Problem;
};

export const useIsFloorsRevealed = (
  floorsCount: number,
  revealedFloorNumber: number
) => {
  // フロア表示
  // 109階を開放した時にはrevealedFloorNumberが109に変わるのでOK
  const [isFloorsRevealed, setIsFloorsRevealed] = useState<boolean[]>(
    Array(floorsCount).fill(false)
  );
  useEffect(() => {
    let newIsFloorsRevealed: boolean[] = isFloorsRevealed;
    for (let i = 0; i < revealedFloorNumber; i++) {
      newIsFloorsRevealed[i] = true;
    }
    setIsFloorsRevealed(newIsFloorsRevealed);
  }, [revealedFloorNumber]);
  return { isFloorsRevealed, setIsFloorsRevealed };
};
