import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  CardContent,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { Guild, User } from "../../interface";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";

export type CommonHeaderProps = {
  userData: User;
  guildData: Guild;
  uid: string;
};

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const { userData, guildData, uid } = props;
  const history = useHistory();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="fixed" color="transparent">
        <Toolbar>
          <Button
            key={"attention"}
            onClick={() => {
              history.push("/about");
            }}
            color="inherit"
            sx={{
              my: 2,
              color: "white",
              display: "block",
            }}
          >
            ABOUT
          </Button>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          {userData.userName == null ? (
            <Button
              color="inherit"
              onClick={() => {
                window.location.href = `https://enigmahouse.jp/login/${process.env.REACT_APP_LOGIN_URL}`;
              }}
            >
              Login
            </Button>
          ) : (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="メニューを開く">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userData.userName}
                    src={userData.picture ?? ""}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleCloseUserMenu}
                open={Boolean(anchorElUser)}
              >
                <CardContent>
                  {uid !== "" ? userData.userName : "ゲスト"} (
                  {guildData.name ?? "未所属"})<br />
                </CardContent>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/config/user");
                  }}
                  sx={{ width: 320 }}
                >
                  <ListItemIcon>
                    <PersonIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>ユーザー設定</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                    history.push("/config/guild");
                  }}
                  sx={{ width: 320 }}
                >
                  <ListItemIcon>
                    <GroupIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>ギルド設定</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {props.children}
    </>
  );
};
