export const monthNameList = [
  "1月 -睦月- 睡眠第一",
  "2月 -如月- 不穏分子",
  "3月 -弥生- 奇想展開",
  "4月 -卯月- 安全運転",
  "5月 -皐月- 単純迷解",
  "6月 -水無月- 縦王無尽 ",
  "7月 -文月- 跳梁白虎",
  "8月 -葉月- 台頭",
  "9月 -長月- 因果応報",
  "10月 -神無月- 好青年",
  "11月 -霜月- 正体不明",
  "12月 -師走- 鉄板 ",
  "年越し謎2021ex",
];
