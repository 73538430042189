export type TweetContent = {
  text: string;
  url?: string;
};

export const tweet = (tweetContent: TweetContent) => {
  window.open(
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetContent.text
    )}` + (tweetContent.url ? `&url=${tweetContent.url}` : "")
  );
};
