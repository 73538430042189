import { Button } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { revealed109Floor } from "../api/cloudFunctionsCaller";
import { floorsCount } from "../App";
import { Guild } from "../interface";
import {
  useIsFloorsRevealed,
  useIsGuildRevealed109Problem,
  useRevealedFloorNumber,
} from "../util/floorHooks";
type Prop = {
  uid: string;
  guildData: Guild;
};

const lastFloorLetterNumberArray = [1, 20, 27, 20, 18];
const colorCodeArray = [
  "#f7a4c0",
  "#edb28c",
  "#fcf588",
  "#d0d0d2",
  "#c9e09b",
  "#94e1f9",
];

export const Open109FButton = (prop: Prop) => {
  const { uid, guildData } = prop;
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const [timeNumberArray, setTimeNumberArray] = useState<Array<number>>([]);
  const [isOpenable, setIsOpenable] = useState(false);
  const [isLetterOk, setIsLetterOk] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const isGuildRevealed109Problem = useIsGuildRevealed109Problem(guildData);
  const revealedFloorNumber = useRevealedFloorNumber(isGuildRevealed109Problem);
  const { isFloorsRevealed, setIsFloorsRevealed } = useIsFloorsRevealed(
    floorsCount,
    revealedFloorNumber
  );

  useEffect(() => {
    setInterval(() => {
      const now = new Date(Date.now() + offset);
      const newArray = [
        Math.floor(now.getFullYear() / 100),
        now.getFullYear() % 100,
        now.getMonth() + 1,
        now.getDate(),
        now.getHours(),
        now.getMinutes(),
      ];
      setTimeNumberArray(newArray);
    }, 1000); //1秒おき
  }, [offset]);

  const [styles, setStyles] = useState<Array<CSSProperties>>([
    {},
    {},
    {},
    {},
    {},
  ]);

  const buttonTextArray = ["あ", "と", "ひ", "と", "つ"];

  useEffect(() => {
    const wordColorCodeArray = new Array<Array<string>>();

    let newIsOpenable = true;
    let newIsLetterOk = [false, false, false, false, false];
    lastFloorLetterNumberArray.forEach((letterNumber, j) => {
      let tempIsOpenable = false;
      const letterColorCodeArray = new Array<string>();
      for (let i = 0; i < 6; i++) {
        if (letterNumber === timeNumberArray[i]) {
          letterColorCodeArray.push(colorCodeArray[i]);
          tempIsOpenable = true;
        }
      }
      newIsLetterOk[j] = tempIsOpenable;
      newIsOpenable = newIsOpenable && tempIsOpenable;
      wordColorCodeArray.push(letterColorCodeArray);
    });
    setIsLetterOk(newIsLetterOk);
    setIsOpenable(newIsOpenable);
    const newStyle = wordColorCodeArray.map((v) => {
      let style: CSSProperties = {
        margin: `${(v.length * 4).toString()}px 0px`,
        padding: "0px 5px",
      };
      let shadowText = "";
      v.forEach((v, i) => {
        if (shadowText !== "") {
          shadowText += ",";
        }
        shadowText += `0px ${((i + 1) * 4).toString()}px ${v}`;
      });
      if (shadowText !== "") {
        style = { ...style, boxShadow: shadowText };
      }
      return style;
    });
    setStyles(newStyle);
  }, [timeNumberArray]);

  const handle109FButton = async () => {
    if (!isOpenable) {
      return;
    }
    let newIsFloorsRevealed = isFloorsRevealed;
    newIsFloorsRevealed[108] = true;
    setIsFloorsRevealed(newIsFloorsRevealed);
    history.push("/floor/109");
    if (uid ?? "" !== "") {
      const result = await revealed109Floor(uid);
    }
  };
  return (
    <Button
      variant={"outlined"}
      disabled={!isOpenable}
      color="primary"
      onClick={handle109FButton}
    >
      {styles.map((v, i) => (
        <div key={i} style={v}>
          {isGuildRevealed109Problem
            ? buttonTextArray[i]
            : isLetterOk[i]
            ? "？"
            : "　"}
        </div>
      ))}
    </Button>
  );
};
