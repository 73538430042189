import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { Floor, ThumbnailData } from "../../interface";

export type ProblemCardContainerProps = {
  thumbnailData: Map<string, ThumbnailData>;
  isFloorsSolved: boolean[];
  isFloorsSolvedByGuild: boolean[];
  handleDisplayFloor: (floorIndex: number) => void;
};

export type ProblemCardProps = ProblemCardContainerProps & {
  floorNumber: number;
};

export const ProblemCard: FC<ProblemCardProps> = (props) => {
  const {
    thumbnailData,
    floorNumber,
    isFloorsSolved,
    isFloorsSolvedByGuild,
    handleDisplayFloor,
  } = props;
  const floorData = thumbnailData.get(floorNumber.toString());
  const color = isFloorsSolved[floorNumber - 1]
    ? "#009624"
    : isFloorsSolvedByGuild[floorNumber - 1]
    ? "#0064b7"
    : undefined;
  return (
    <Grid item xs={12} sm={4}>
      <Card sx={{ bgcolor: color }}>
        <CardActionArea onClick={() => handleDisplayFloor(floorNumber)}>
          <CardMedia
            component="img"
            image={floorData?.problemImage}
            alt={floorData?.title}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {floorData?.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              by {floorData?.author}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
