import {
  Container,
  CssBaseline,
  GlobalStyles,
  LinearProgress,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "firebase/auth";
import { getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { checkSessionCookie } from "./api/cloudFunctions/sso/checkSessionCookie";
import { loginWithCustomToken } from "./api/cloudFunctions/sso/loginWithCustomToken";
import { createUser } from "./api/cloudFunctionsCaller";
import { getUserDataOnce, loadGuildData, loadUserData } from "./api/dataLoader";
import { CommonHeader } from "./components/CommonContents/CommonHeader";
import { Config } from "./components/Config";
import { FloorPage } from "./components/Floor";
import FloorSelect from "./components/FloorSelect";
import { NotFound } from "./components/NotFound";
import { Rule } from "./components/Rule";
import "./css/App.css";
import { initialGuild, initialUser } from "./data/initialData";
import headerVisual from "./img/header.jpg";
import keyVisual from "./img/keyVisual.png";
import { logger } from "./lib/logger";
import { NowLoading } from "./components/NowLoading";
import { useAuthState } from "react-firebase-hooks/auth";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#fbc21d",
      dark: "#90a4ae",
    },
    secondary: {
      main: "#fa4705",
    },
    success: {
      main: "#00c853",
      contrastText: "#ffffff",
    },
    info: {
      main: "#2196f3",
    },
    background: {
      paper: "#302835",
      default: "#181053",
    },
    action: {
      disabledBackground: "#EADAAF",
      disabled: "#ffffff",
    },
  },
};

const theme = createTheme(themeOptions);

let stopUserDataSnapshot = () => {};
let stopGuildDataSnapshot = () => {};
export const floorsCount = 108;

function App() {
  //userData取得
  const [userData, setUserData] = useState(initialUser);
  const [guildData, setGuildData] = useState(initialGuild);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [isGuestMode, setIsGuestMode] = useState(false);
  const [uid, setUid] = useState<string | null>("");

  // Enigmahouseでのログイン後にユーザーデータを設定する処理
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    //1. 年越し謎側でログイン状況の確認
    if (loading) {
      return;
    }
    //2. sessionCookieで認証
    //parentUidがバグってる
    checkSessionCookie().then(({ uid, customToken }) => {
      if (user !== null && user !== undefined) {
        if (uid !== null && customToken !== null) {
          if (uid === user.uid) {
            //2-1. 矛盾しない
            logger("case2-1", user.uid);
            setUid(user.uid);
          } else {
            logger("case2-2");
            logger(uid, user.uid);
            //2-2. 矛盾しているのでログアウト+sessionCookieをもとに再ログイン
            signOut(auth).then(() => {
              loginWithCustomToken(customToken).then((result) => {
                setUid(result);
              });
            });
          }
        } else {
          logger("case2-3");
          //2-3. sessionCookie再発行する
          window.location.href = `https://enigmahouse.jp/refresh/${process.env.REACT_APP_LOGIN_URL}`;
        }
      } else {
        if (uid !== null && customToken !== null) {
          logger("case4-1");
          //4-1. sessionCookieをもとにログイン
          loginWithCustomToken(customToken).then((result) => {
            setUid(result);
          });
        } else {
          logger("case4-2");
          //4-2. ゲストモード
          setUserDataLoaded(true);
          setUid("");
        }
      }
    });
  }, [loading, auth, user]);

  // ユーザーIDが変わった時
  useEffect(() => {
    if (uid !== "" && uid !== null) {
      const f = async () => {
        stopUserDataSnapshot();
        const newUserData = await getUserDataOnce(uid);
        if (!newUserData) {
          await createUser(uid);
        }
        stopUserDataSnapshot = loadUserData(
          uid,
          setUserData,
          setUserDataLoaded
        );
      };
      f();
    }
  }, [uid]);
  // ギルドIDが変わった時
  useEffect(() => {
    if (userData.joinedGuildId !== "") {
      stopGuildDataSnapshot();
      stopGuildDataSnapshot = loadGuildData(
        userData.joinedGuildId,
        setGuildData
      );
    }
  }, [userData.joinedGuildId]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { backgroundColor: "#181053" },
        }}
      />
      {!userDataLoaded || uid === null ? (
        <>
          <LinearProgress />
          <NowLoading />
        </>
      ) : (
        <CommonHeader userData={userData} guildData={guildData} uid={uid}>
          <Switch>
            <Route exact path="/">
              <Link to={"/"}>
                <img
                  src={keyVisual}
                  style={{ width: "100%" }}
                  alt="keyVisual"
                ></img>
              </Link>
              <Container maxWidth="xl">
                <FloorSelect
                  guildData={guildData}
                  userData={userData}
                  uid={uid}
                />
              </Container>
            </Route>
            <Route path="/floor/:floorIndex">
              <Link to={"/"}>
                <img
                  src={headerVisual}
                  style={{ width: "100%" }}
                  alt="headerVisual"
                ></img>
              </Link>
              <Container maxWidth="xl">
                <FloorPage
                  guildData={guildData}
                  userData={userData}
                  uid={uid}
                  floorsCount={floorsCount}
                />
              </Container>
            </Route>
            <Route path="/config/">
              <Link to={"/"}>
                <img
                  src={headerVisual}
                  style={{ width: "100%" }}
                  alt="headerVisual"
                ></img>
              </Link>
              <Container maxWidth="xl">
                <Config
                  uid={uid}
                  userData={userData}
                  guildData={guildData}
                  setUid={setUid}
                  setIsGuestMode={setIsGuestMode}
                  isGuestMode={isGuestMode}
                />
              </Container>
            </Route>
            <Route path="/about">
              <Link to={"/"}>
                <img
                  src={headerVisual}
                  style={{ width: "100%" }}
                  alt="headerVisual"
                ></img>
              </Link>
              <Container maxWidth="xl">
                <Rule uid={uid} userData={userData} guildData={guildData} />
              </Container>
            </Route>
            <Route path="*">
              <Link to={"/"}>
                <img
                  src={headerVisual}
                  style={{ width: "100%" }}
                  alt="headerVisual"
                ></img>
              </Link>
              <NotFound />
            </Route>
          </Switch>
        </CommonHeader>
      )}
    </ThemeProvider>
  );
}

export default App;
