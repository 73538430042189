import {
  doc,
  getDoc,
  onSnapshot,
  collection,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { initialGuild } from "../data/initialData";
import { Floor, Guild, ThumbnailData, User, UserWithId } from "../interface";
import { hashText } from "../util/hashText";
import {
  floorConverter,
  guildConverter,
  userConverter,
} from "./util/firebaseConverter";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

export const getUserParentUid = (uid: string): Promise<string> => {
  const db = getFirestore();
  const docRef = doc(db, "user", uid);

  return getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        return doc.data()?.parentUid;
      } else {
        return undefined;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadUserData = (
  uid: string | undefined,
  setUserData: (userData: User) => void,
  setUserDataLoaded: (arg: boolean) => void
) => {
  if (uid === undefined) {
    return () => {};
  }
  const db = getFirestore();
  const docRef = doc(db, "user", uid).withConverter(userConverter);
  //let success = false;
  const stopUserDataSnapshot = onSnapshot(docRef, (doc) => {
    const newUserData = doc.data();
    if (newUserData) {
      setUserData(newUserData);
      //success = true;
    } else {
      console.log("No such document!");
    }
    setUserDataLoaded(true);
  });
  return stopUserDataSnapshot;
};

export const loadAllUserData = (setAllUserData: (g: UserWithId[]) => void) => {
  const db = getFirestore();
  const colRef = collection(db, "user").withConverter(userConverter);
  //let success = false;
  const stopUserDataSnapshot = getDocs(colRef).then((col) => {
    const allUserData = col.docs.map((doc) => ({
      userName: doc.data()?.userName,
      guildId: doc.data()?.guildId,
      joinedGuildId: doc.data()?.joinedGuildId,
      solvedCount: doc.data()?.solvedCount,
      floors: doc.data()?.floors,
      picture: doc.data()?.picture,
      uid: doc.id,
    }));
    setAllUserData(allUserData);
  });
  return stopUserDataSnapshot;
};

export const loadGuildData = (
  guildId: string,
  setGuildData: (guildData: Guild) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "guild", guildId).withConverter(guildConverter);
  //let success = false;
  const stopGuildDataSnapshot = onSnapshot(docRef, (doc) => {
    const newGuildData = doc.data();
    if (newGuildData) {
      setGuildData(newGuildData);
      //success = true;
    } else {
      setGuildData(initialGuild);
      console.log("No such document!");
    }
  });
  return stopGuildDataSnapshot;
};

export const loadAllGuildData = (setAllGuildData: (g: Guild[]) => void) => {
  const db = getFirestore();
  const colRef = collection(db, "guild").withConverter(guildConverter);
  //let success = false;
  const stopGuildDataSnapshot = onSnapshot(colRef, (col) => {
    const allGuildData = col.docs.map((doc) => doc.data());
    setAllGuildData(allGuildData);
  });
  return stopGuildDataSnapshot;
};

export const loadRankingMetaData = async (
  setRankingMeta: (rankingMetaData: { ranking: Date }) => void
) => {
  const db = getFirestore();
  getDoc(doc(db, "doc", "ranking")).then((doc) => {
    const data = {
      ranking: doc.data()?.ranking.toDate(),
    };
    setRankingMeta(data);
  });
};

//floorには問題URLのみを保存する
export const loadFloorData = async (
  floorName: string,
  setFloorData: (arg: Floor) => void
) => {
  const db = getFirestore();
  const floorId = (await hashText(floorName)).slice(0, 8);
  const docRef = doc(db, "floor", floorId).withConverter(floorConverter);
  getDoc(docRef)
    .then(async (doc) => {
      const newFloorData = doc.data();
      if (newFloorData) {
        setFloorData(newFloorData);
      } else {
        console.log("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
    });
};

export const loadGuildAdminData = async (
  guildId: string,
  setGuildInviteCode: (inviteCode: string) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "guildAdmin", guildId);
  return await getDoc(docRef)
    .then((doc) => {
      if (doc.exists()) {
        const newInviteCode: string = doc.data()?.inviteCode;
        if (newInviteCode) {
          setGuildInviteCode(newInviteCode);
          return true;
        } else {
          console.log("no data :doc.data().?inviteCode");
          return false;
        }
      } else {
        console.log("No such document!");
        return false;
      }
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      return false;
    });
};

export const getRevealedFloorNumberSnapshot = async (
  setRevealedFloorNumber: (prop: number) => void
) => {
  const db = getFirestore();
  const docRef = doc(db, "revealedFloorNumber", "revealedFloorNumber");

  const stopRevealedFloorNumberSnapshot = onSnapshot(docRef, (doc) => {
    if (doc) {
      if (typeof doc.data()?.revealedFloorNumber === "number") {
        setRevealedFloorNumber(doc.data()?.revealedFloorNumber);
      } else {
        console.log("No revealedFloorNumber or type error");
      }
    }
  });
  return stopRevealedFloorNumberSnapshot;
};

export const getUserName = async (memberId: string) => {
  const db = getFirestore();
  const docRef = doc(db, "user", memberId);
  const memberName = await getDoc(docRef).then((doc) => {
    let newMemberName = "";
    if (doc.exists()) {
      newMemberName = doc.data()?.userName;
    }
    return newMemberName;
  });
  return { memberName, memberId };
};

export const getUserDataOnce = async (uid: string) => {
  const db = getFirestore();
  const docRef = doc(db, "user", uid).withConverter(userConverter);
  const userData = (await getDoc(docRef)).data();
  return userData;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measureId: process.env.REACT_APP_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(firebaseConfig);

export const loadImage = async (
  id: number,
  isAnswer: boolean,
  fileType: string
) => {
  const storage = getStorage(firebaseApp);
  if (!fileType) {
    return "";
  }
  if (id === 108) {
    const url: string = await getDownloadURL(
      ref(
        storage,
        `2021-${(Math.floor(id / 9) + 1).toString()}-${(
          id +
          1 -
          Math.floor(id / 9) * 9
        ).toString()}${isAnswer ? "a" : ""}fb746078.${fileType}`
      )
    );

    return url;
  }
  const url: string = await getDownloadURL(
    ref(
      storage,
      `2021-${(Math.floor(id / 9) + 1).toString()}-${(
        id +
        1 -
        Math.floor(id / 9) * 9
      ).toString()}${isAnswer ? "a" : ""}.${fileType}`
    )
  );

  return url;
};

export const getIsGuildRevealed109Problem = async (
  guildMemberIds: string[]
) => {
  const db = getFirestore();
  let ret = false;
  const promiseArray: Array<Promise<any>> = [];
  guildMemberIds.forEach((v) => {
    if ((v ?? "") !== "") {
      promiseArray.push(
        getDoc(doc(db, "user", v).withConverter(userConverter))
      );
    }
  });
  const result = await Promise.allSettled(promiseArray);
  result.forEach((v) => {
    if (v.status === "fulfilled") {
      const userData: User = v.value.data();
      if (userData?.is109FloorRevealed) {
        ret = true;
      }
    }
  });
  return ret;
};

export const loadThumbnailData = async (revealedFloorNumber: number) => {
  const db = getFirestore();
  const colRef = collection(db, "imageUrl");
  const rawData = (await getDocs(colRef)).docs[0];
  const thumbnailData = new Map<string, ThumbnailData>();
  for (let i = 1; i <= revealedFloorNumber; i++) {
    const data = rawData.get(i.toString());
    if (data) {
      thumbnailData.set(i.toString(), {
        author: data?.author,
        problemImage: data?.url,
        title: data?.title,
      });
    }
  }
  return thumbnailData;
};
