import { Box, Button, Grid, Slider } from "@mui/material";
import { fabric } from "fabric";
import { useEffect, useState } from "react";

type Prop = {
  canvas: fabric.Canvas | undefined;
  setCanvas: (prop: fabric.Canvas) => void;
};

//7色+白灰黒
const colorList = [
  "#e74c3c",
  "#e67e22",
  "#f1c40f",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",

  "#000000",
  "#bdc3c7",
  "#eeeeee",
];

export const CanvasUtil = (prop: Prop) => {
  const { canvas, setCanvas } = prop;
  const [penWidth, setPenWidth] = useState(2);
  const [penColor, setPenColor] = useState("#000");
  const [history, setHistory] = useState<Array<fabric.Object>>([]);

  useEffect(() => {
    setPenWidth(canvas?.freeDrawingBrush.width ?? 5);
    setPenColor(canvas?.freeDrawingBrush.color ?? "#000");
  }, []);

  const handlePenWidth = (event: Event, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      return;
    }
    setPenWidth(newValue);
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.freeDrawingBrush.width = newValue;
      setCanvas(newCanvas);
    }
  };

  const changePenColor = (colorValue: string) => {
    //colorvalue sample: #fff, #c0ffee, etc.

    console.log(colorValue);
    setPenColor(colorValue);
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.freeDrawingBrush.color = colorValue;
      setCanvas(newCanvas);
    }
  };

  const makeDrawingModeOff = () => {
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.isDrawingMode = false;
      setCanvas(newCanvas);
    }
  };
  const makeDrwaingModeOn = () => {
    if (canvas !== undefined) {
      let newCanvas = canvas;
      newCanvas.isDrawingMode = true;
      setCanvas(newCanvas);
    }
  };

  const clearHandwrite = () => {
    if (canvas !== undefined) {
      canvas._objects.splice(0);
      canvas.renderAll();
      setExpectedHandWriteLength(0);
    }
  };

  const [expectedHandWriteLengh, setExpectedHandWriteLength] = useState(0);

  const undo = () => {
    if (canvas === undefined) {
      return;
    }
    if (canvas._objects.length > 0) {
      const temp = canvas._objects.pop();
      if (temp !== undefined) {
        history.push(temp);
      }
      setExpectedHandWriteLength(canvas._objects.length);
      canvas.requestRenderAll();
    }
  };
  const redo = () => {
    if (canvas === undefined) {
      return;
    }
    if (
      history.length > 0 &&
      expectedHandWriteLengh === canvas._objects.length
    ) {
      const temp = history.pop();
      if (temp !== undefined) {
        canvas.add(temp);
        setExpectedHandWriteLength(expectedHandWriteLengh + 1);
      }
    }
    canvas.requestRenderAll();
  };

  return (
    <Grid component="div" sx={{ my: 1 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={makeDrawingModeOff}
        sx={{ mx: 0.5 }}
      >
        drag Mode
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={makeDrwaingModeOn}
        sx={{ mx: 0.5 }}
      >
        Pen Mode
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={clearHandwrite}
        sx={{ mx: 0.5 }}
      >
        clear
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={undo}
        sx={{ mx: 0.5 }}
      >
        undo
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={redo}
        sx={{ mx: 0.5 }}
      >
        redo
      </Button>
      <Grid component="div" sx={{ my: 1 }}>
        <p>現在のペンの色:</p>
        <div
          style={{
            backgroundColor: penColor,
            width: "20px",
            height: "20px",
          }}
        ></div>
        <p>カラーパレット:</p>
        <Box
          sx={{
            flexWrap: "wrap",
            display: "grid",
            gridTemplateColumns: "repeat(7, 1fr)",
            width: "60px",
            border: "1px",
          }}
        >
          {colorList.map((v) => (
            <div
              style={{ backgroundColor: v, width: "20px", height: "20px" }}
              key={v}
              onClick={() => {
                changePenColor(v);
              }}
            ></div>
          ))}
        </Box>
      </Grid>
      <p>ペンの太さ:</p>
      <Slider
        aria-label="PenWidth"
        value={penWidth}
        valueLabelDisplay="auto"
        onChange={handlePenWidth}
        min={1}
        step={1}
        marks
        max={15}
      />
    </Grid>
  );
};
