import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measureId: process.env.REACT_APP_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
const REGION = "asia-northeast1";
const functions = getFunctions(app, REGION);

export const correctAnswerSent = async (uid: string, floorId: string) => {
  let returnData: { isCorrect: boolean } = {
    isCorrect: false,
  };
  const onCallFromTokyo = httpsCallable(functions, "checkAnswer");
  try {
    await onCallFromTokyo({
      floorId: floorId,
      uid: uid,
    }).then((result) => {
      const data = result.data as { isCorrect: boolean };
      returnData.isCorrect = data.isCorrect;
    });
  } catch (e) {
    throw e;
  }
  return returnData;
};

export const saveGuildName = async (
  uid: string,
  guildNameInputValue: string,
  setMessage: (message: string) => void
) => {
  if (guildNameInputValue !== "") {
    const setNewGuild = httpsCallable(functions, "updateGuildName");
    try {
      if (await setNewGuild({ newGuildName: guildNameInputValue, uid: uid })) {
        setMessage("saved");
      } else {
        setMessage("failed saving");
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    setMessage("ギルド名を入力してください!");
  }
};

export const saveUserName = async ({
  uid,
  userNameInputValue,
  setMessage,
  picture,
}: {
  uid: string;
  userNameInputValue: string;
  setMessage: (message: string) => void;
  picture?: string;
}) => {
  if (userNameInputValue !== "") {
    const setNewName = httpsCallable(functions, "updateName");
    try {
      await setNewName({
        newUserName: userNameInputValue,
        uid,
        picture: picture ?? "",
      });
      setMessage("saved");
    } catch (e) {
      console.log(e);
    }
  } else {
    setMessage("Enter user Name!");
  }
};

export const makeGuildInvite = async (uid: string) => {
  const makeInviteCode = httpsCallable(functions, "makeInviteCode");
  try {
    const result = (await makeInviteCode({ uid: uid })).data as {
      success: boolean;
      reason: string;
    };
    if (result.success) {
    } else {
      console.log(result.reason);
    }
  } catch (e) {
    console.log(e);
  }
};

export const receiveGuildInviteCode = async (
  inviteCode: string,
  oldGuildId: string,
  uid: string
) => {
  const receiveInviteCode = httpsCallable(functions, "receiveInviteCode");
  try {
    const result = (
      await receiveInviteCode({
        inviteCode,
        oldGuildId,
        uid,
      })
    ).data as { success: boolean; reason: string };
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const kickMember = async (uid: string, kickUid: string) => {
  const kickGuildMember = httpsCallable(functions, "kickGuildMember");
  try {
    const result = await kickGuildMember({ kickUid, uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const leaveGuild = async (uid: string) => {
  const leaveFromGuild = httpsCallable(functions, "leaveGuild");
  try {
    const result = await leaveFromGuild({ uid });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const changeGuildOwner = async (
  uid: string,
  guildId: string,
  newOwnerId: string
) => {
  const changeOwner = httpsCallable(functions, "changeGuildOwner");
  try {
    const result = await changeOwner({
      uid: uid,
      guildId: guildId,
      newOwnerId: newOwnerId,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const createUser = async (uid: string) => {
  const createUser = httpsCallable(functions, "createUser");
  try {
    const result = await createUser({
      uid: uid,
      newUserName: "",
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const revealed109Floor = async (uid: string) => {
  const revealed109Floor = httpsCallable(functions, "revealed109Floor");
  try {
    const result = await revealed109Floor({
      uid: uid,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};
