import { Button, Grid, Icon } from "@mui/material";
import { useHistory } from "react-router-dom";

export const NowLoading = () => {
  let history = useHistory();
  return (
    <>
      <div>読み込み中です。 </div>
      <Grid component="div" sx={{ my: 1 }}>
        <Button
          variant="contained"
          startIcon={<Icon>apps</Icon>}
          onClick={() => {
            history.push("/");
          }}
        >
          年越し謎2021トップに戻る
        </Button>
      </Grid>
    </>
  );
};
