import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { leaveGuild } from "../../api/cloudFunctionsCaller";
import { User } from "../../interface";

export const LeaveGuild = ({
  setIsButtonDisabled,
  userData,
  uid,
}: {
  setIsButtonDisabled: (arg: boolean) => void;
  userData: User;
  uid: string;
}) => {
  const history = useHistory();

  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [leaveResultDialogOpen, setLeaveResultDialogOpen] = useState(false);
  const [leaveResult, setLeaveResult] = useState(false);

  const handleLeaveDialogOpen = () => {
    setLeaveDialogOpen(true);
  };
  const handleLeaveDialogClose = () => {
    setLeaveDialogOpen(false);
  };

  const handleLeaveResultDialogOpen = () => {
    setLeaveResultDialogOpen(true);
  };
  const handleLeaveResultDialogClose = () => {
    setLeaveResultDialogOpen(false);
  };

  const handleLeaveButton = async () => {
    setIsButtonDisabled(true);
    handleLeaveDialogClose();
    const result = await leaveGuild(uid);
    setLeaveResult((result?.data as boolean) ?? false);
    setIsButtonDisabled(false);
    handleLeaveResultDialogOpen();
  };

  return (
    <>
      <Grid>
        <Button variant="contained" onClick={handleLeaveDialogOpen}>
          ギルドを退会する
        </Button>
      </Grid>

      <Dialog
        open={leaveDialogOpen}
        onClose={handleLeaveDialogClose}
        aria-labelledby="alert-kickdialog-title"
        aria-describedby="alert-kickdialog-description"
      >
        <DialogTitle id="alert-kickdialog-title">ギルドからの退会</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-kickdialog-description">
            ギルドから退会しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeaveDialogClose}>キャンセル</Button>
          <Button
            onClick={() => {
              handleLeaveButton();
            }}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={leaveResultDialogOpen}
        onClose={handleLeaveResultDialogClose}
        aria-labelledby="alert-kickresultdialog-title"
        aria-describedby="alert-kickresultdialog-description"
      >
        <DialogTitle id="alert-kickresultdialog-title">
          ギルドからの退会
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-kickresultdialog-description">
            {leaveResult ? (
              <>ギルドを退会しました。。</>
            ) : (
              <>ギルドからの退会に失敗しました。時間をおいてお試しください。</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeaveResultDialogClose}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
