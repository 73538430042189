import axios from "axios";
import { initialFloor } from "../data/initialData";
import { Floor } from "../interface";
import { loadImage } from "./dataLoader";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const sheetId = process.env.REACT_APP_SPREADSHEET_ID;
/*
export const getAllFloorFromGoogleSheet = async () => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/allData2!A2:H110?key=${apiKey}`;
  return await googleSheetReader(url);
};
*/
export const getOneFloorFromGoogleSheet = async (floorNumber: number) => {
  const row = (floorNumber + 1).toString();
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/allData!A${row}:H${row}?key=${apiKey}`;
  return (await googleSheetReader(url)).get("1") ?? null;
};

const googleSheetReader = async (url: string) => {
  return axios
    .get(url)
    .then(async (res) => {
      const floors = new Map<string, Floor>();
      let rawData: Array<Array<string>> = res.data.values;
      const promiseArray: Array<Promise<string>> = [];
      rawData.map((v) => {
        promiseArray.push(loadImage(parseInt(v[0]) - 1, false, v[6]));
        promiseArray.push(loadImage(parseInt(v[0]) - 1, true, v[7]));
      });
      const urls = (await Promise.allSettled(promiseArray)).map((v) =>
        v.status === "fulfilled" ? v.value : ""
      );
      rawData.map((v, i) => {
        const floorData: Floor = {
          title: v[2],
          author: v[3],
          answerArray: v[4]?.split(","),
          problemImage: urls[i * 2],
          solutionImage: urls[i * 2 + 1],
          authorTwitterAccount: v[5],
        };
        floors.set((i + 1).toString(), floorData);
      });
      return floors;
    })
    .catch((error) => {
      console.error(error);
      return new Map<string, Floor>();
    });
};
