import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import headerVisual from "../img/header.jpg";
import React, { useState } from "react";
import { Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { Guild, User } from "../interface";
import { ChangeUserName } from "./ConfigContents/ChangeUserName";
import { GuildConfig } from "./ConfigContents/GuildConfig";
import { NewUser } from "./NewUser";
import { PageTitle } from "./typography/PageTitle";

export type ConfigProps = {
  guildData: Guild;
  userData: User;
  uid: string;
};

export const Config = ({
  guildData,
  userData,
  uid,
  setUid,
  setIsGuestMode,
  isGuestMode,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
  setUid: (prop: string) => void;
  setIsGuestMode: (prop: boolean) => void;
  isGuestMode: boolean;
}) => {
  const props = { guildData, userData, uid };
  const history = useHistory();

  //各種設定中は他のボタンを押させない
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <Switch>
          <Route exact path="/config">
            <Grid item xs={12}>
              <Typography variant="h4" component="h1">
                各種設定
              </Typography>
              <Button
                variant="contained"
                startIcon={<Icon>person</Icon>}
                onClick={() => history.push("/config/user")}
              >
                ユーザー設定
              </Button>
              {!isGuestMode && (
                <Button
                  variant="contained"
                  startIcon={<Icon>group</Icon>}
                  onClick={() => history.push("/config/guild")}
                >
                  ギルド設定
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={isButtonDisabled}
                startIcon={<Icon>apps</Icon>}
                onClick={() => history.push("/")}
              >
                年越し謎2021トップに戻る
              </Button>
            </Grid>
          </Route>
          <Route exact path="/config/new-user">
            <NewUser
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              {...props}
              setUid={setUid}
              setIsGuestMode={setIsGuestMode}
              isGuestMode={isGuestMode}
            ></NewUser>
          </Route>
          <Route exact path="/config/user">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isButtonDisabled}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={12}>
              <PageTitle>ユーザー設定</PageTitle>
            </Grid>

            <Card>
              <CardContent>
                <Typography variant="h4" component="h1">
                  ユーザー情報
                </Typography>
                <p>アカウント名: {userData.userName}</p>
                <p>ユーザーID: {uid}</p>
                <p>ギルド名: {guildData.name}</p>
                <p>個人正解数: {userData.solvedCount}</p>
                <p>ギルド正解数: {guildData.solvedCount}</p>
              </CardContent>
            </Card>
            <ChangeUserName
              newUser={false}
              uid={uid}
              setIsButtonDisabled={setIsButtonDisabled}
              isGuestMode={isGuestMode}
            />
          </Route>
          <Route exact path="/config/guild">
            <GuildConfig
              props={props}
              isButtonDisabled={isButtonDisabled}
              setIsButtonDisabled={setIsButtonDisabled}
              isGuestMode={isGuestMode}
            />
          </Route>
        </Switch>
        <Divider />
      </Grid>
    </>
  );
};
