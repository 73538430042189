import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { monthNameList } from "../../data/monthNameList";
import { ProblemCard, ProblemCardContainerProps } from "./ProblemCard";

export type MonthBoxProps = {
  monthIndex: number;
  revealedFloorNumber: number;
} & ProblemCardContainerProps;

export const MonthBox: FC<MonthBoxProps> = (props) => {
  const { monthIndex, revealedFloorNumber, ...rest } = props;

  return (
    <div id={"month-" + (monthIndex + 1)}>
      <Typography variant="h4" component="h1" sx={{ m: 2 }}>
        {monthNameList[monthIndex]}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {[...new Array(Math.min(9, revealedFloorNumber - monthIndex * 9))].map(
          (_, i) => {
            return (
              <ProblemCard
                floorNumber={monthIndex * 9 + i + 1}
                {...rest}
                key={(monthIndex * 9 + i + 1).toString()}
              />
            );
          }
        )}
      </Grid>
    </div>
  );
};
