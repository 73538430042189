import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Fab, Grid, Pagination, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { floorsCount } from "../App";
import { monthNameList } from "../data/monthNameList";
import { Floor, Guild, User } from "../interface";
import {
  useThumbnailData,
  useIsFloorsRevealed,
  useIsFloorsSolvedByGuild,
  useIsFloorsSolvedByPlayer,
  useIsGuildRevealed109Problem,
  useIsGuildSolvedAll108Problem,
  useRevealedFloorNumber,
} from "../util/floorHooks";
import { Open109FButton } from "./109FButton";
import { ScrollTop } from "./button/ScrollTop";
import { TweetButton } from "./button/TweetButton";
import { MonthBox } from "./FloorSelectContents/MonthBox";

const FloorSelect = ({
  guildData,
  userData,
  uid,
}: {
  guildData: Guild;
  userData: User;
  uid: string;
}) => {
  let history = useHistory();

  const isGuildRevealed109Problem = useIsGuildRevealed109Problem(guildData);
  const revealedFloorNumber = useRevealedFloorNumber(isGuildRevealed109Problem);
  const thumbnailData = useThumbnailData(revealedFloorNumber);

  const isFloorsSolvedByUser = useIsFloorsSolvedByPlayer(floorsCount, userData);
  const isFloorsSolvedByGuild = useIsFloorsSolvedByGuild(
    floorsCount,
    guildData
  );
  const isGuildSolved108Problem = useIsGuildSolvedAll108Problem(
    floorsCount,
    isFloorsSolvedByGuild
  );
  const { isFloorsRevealed } = useIsFloorsRevealed(
    floorsCount,
    revealedFloorNumber
  );

  const [isGuildSolved109Problem, setIsGuildSolved109Problem] = useState(false);
  useEffect(() => {
    if (guildData.floors["109"]) {
      setIsGuildSolved109Problem(true);
    }
  }, [guildData.floors]);

  const range = (stop: number) =>
    new Array<string>(stop).fill("").map((_, i) => (i + 1).toString());

  const handleDisplayFloor = (floorIndex: number) => {
    isFloorsRevealed[floorIndex - 1] &&
      history.push("/floor/" + floorIndex.toString());
  };
  const handlePagination = React.useCallback((e) => {
    const month = e.target.innerText;
    const offset = {
      // @ts-ignore
      top: document.querySelector(`#month-${month}`)?.offsetTop ?? 0,
      // @ts-ignore
      left: document.querySelector(`#month-${month}`)?.offsetLeft ?? 0,
      behavior: "smooth",
    };
    console.log(document.querySelector(`#month-${month}`));
    // @ts-ignore
    window.scrollTo(offset);
  }, []);

  return (
    <>
      <div>
        {isGuildSolved109Problem ? (
          <div>
            <Typography variant="h4" component="h1" sx={{ m: 2 }}>
              あなたは、あとひとつの謎も解き明かした！すごい！！！
            </Typography>
            <TweetButton
              tweetContent={{
                text: `あなたは、あとひとつの謎も解き明かした！すごい！！！
#年越し謎2021
https://goodbye2021.enigmahouse.jp`,
              }}
            />
            <Open109FButton uid={uid} guildData={guildData}></Open109FButton>
          </div>
        ) : isGuildSolved108Problem ? (
          <div>
            <Typography variant="h4" component="h1" sx={{ m: 2 }}>
              あなたはすべての煩悩を取り払った❣️すごい❣️
            </Typography>
            <TweetButton
              tweetContent={{
                text: `あなたはすべての煩悩を取り払った❣️すごい❣️
#年越し謎2021
https://goodbye2021.enigmahouse.jp`,
              }}
            />
            <Open109FButton uid={uid} guildData={guildData}></Open109FButton>
          </div>
        ) : (uid ?? "") !== "" ? (
          <div>
            <Typography variant="h5" component="h1" sx={{ m: 2 }}>
              あなたは108問中 {guildData.solvedCount.toString()}問解き明かした！
            </Typography>
          </div>
        ) : (
          <div>
            <Typography variant="h5" component="h1" sx={{ m: 2 }}>
              ログインすることで解答状況を記録できます。
            </Typography>
          </div>
        )}
        {(uid ?? "") !== "" && (
          <Grid component="p" sx={{ my: 1 }}>
            <TweetButton
              tweetContent={{
                text: `ギルドで合計${guildData.solvedCount}問を解き明かした！
#年越し謎2021
https://goodbye2021.enigmahouse.jp`,
              }}
            >
              ギルド進捗をツイート
            </TweetButton>
            <TweetButton
              tweetContent={{
                text: `個人で合計${userData.solvedCount}問を解き明かした！
#年越し謎2021
https://goodbye2021.enigmahouse.jp`,
              }}
            >
              個人進捗をツイート
            </TweetButton>
          </Grid>
        )}
        <Pagination
          count={13}
          boundaryCount={13}
          hidePrevButton
          hideNextButton
          size="large"
          onClick={handlePagination}
          id="back-to-top-anchor"
        />
        <Box
          id="month-box"
          sx={{
            flexWrap: "wrap",
            display: "grid",
            gridTemplateColumns: `repeat(1, 1fr)`,
            width: "100%",
            ml: 1,
          }}
        >
          {[...new Array(Math.ceil(revealedFloorNumber / 9))].map((_, i) => (
            <MonthBox
              monthIndex={i}
              revealedFloorNumber={revealedFloorNumber}
              thumbnailData={thumbnailData}
              isFloorsSolved={isFloorsSolvedByUser}
              isFloorsSolvedByGuild={isFloorsSolvedByGuild}
              handleDisplayFloor={handleDisplayFloor}
              key={monthNameList[i] ?? ""}
            />
          ))}
        </Box>
      </div>
      <ScrollTop>
        <Fab color="secondary" size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default FloorSelect;
