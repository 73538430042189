import { Guild, User, Floor, UserWithId } from "../interface";

export const initialUser: User = {
  userName: null,
  guildId: "",
  joinedGuildId: "",
  solvedCount: 0,
  floors: {},
  picture: null
};
export const initialGuild: Guild = {
  name: null,
  memberIds: [""],
  floors: {},
  solvedCount: 0,
  active: false,
  guildOwner: "",
};
export const initialFloor: Floor = {
  problemImage: "",
  solutionImage: "",
  author: "nodata",
  title: "nodata",
  authorTwitterAccount: "",
  answerArray: [],
};

export const initialUserWithId: UserWithId = {
  userName: null,
  guildId: "",
  joinedGuildId: "",
  solvedCount: 0,
  floors: {},
  uid: "",
  picture: null
};
